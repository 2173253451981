/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getSpaceGroteskFontStyle } from "../../common/FontStyles";
import {
  directionStyles,
  getHeightCss,
  getWidthCss,
} from "../../common/GlobalStyle";
import { ReactComponent as AeonLogoSvg } from "../../images/aeon-logo.svg";
import { ReactComponent as SeoulFictionLogoSvg } from "../../images/sf-logo.svg";
import { ReactComponent as AiNetworkLogoSvg } from "../../images/ai-logo.svg";
import { ReactComponent as DiscordSvg } from "../../images/discord.svg";
import { ReactComponent as TwitterSvg } from "../../images/twitter.svg";
import { ReactComponent as LeftIcon1Svg } from "../../images/left-icon-1.svg";
import { ReactComponent as LeftIcon2Svg } from "../../images/left-icon-2.svg";
import { ReactComponent as BadgeSvg } from "../../images/badge.svg";
import { useEffect, useMemo, useState } from "react";
import { useWindowSize } from "../../components/UseWindowSize";
import { useGlitch } from "react-powerglitch";
import { useWeb3React } from '@web3-react/core'
import { walletAddrCompression } from '../../common/utils'

import moment from "moment";
import { Injected } from "../../Connector";

export const MainScreen = () => {
  const [topButtonState, setTopButtonState] = useState(false);
  const [topAipPress, setTopAipPress] = useState(false);
  const [iconStep, setIconStep] = useState(1);
  const [iconYPos, setIconYPos] = useState(263);
  const [leftTime, setLeftTime] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const windowSize = useWindowSize();
  const glitch = useGlitch();
  const {activate, deactivate, account} = useWeb3React();
  

  const calcLeftTime = () => {
    const time1 = moment("2023-06-01 12:00:00");
    const time2 = moment.now();
    const diff = moment.duration(time1.diff(time2));
    const dateString = `${diff.months().toString().padStart(2, "0")}:${diff
      .days()
      .toString()
      .padStart(2, "0")}:${diff.hours().toString().padStart(2, "0")}:${diff
      .minutes()
      .toString()
      .padStart(2, "0")}:${diff.seconds().toString().padStart(2, "0")}`;
    setLeftTime(dateString);
  };

  const onClickWalletConnectBtn = () => {
    activate(Injected);
    setTopButtonState(true);
  }

  useEffect(() => {
    calcLeftTime();
    setInterval(() => calcLeftTime(), 1000);
  }, []);

  const RenderTopButton = () => {
    const renderNotConnect = () => {
      return (
        <>
          <div css={getHeightCss(48)} />
          <div css={[directionStyles.row, getHeightCss(50)]}>
            <div css={getWidthCss(1135)} />
            <div css={styles.topButton} onClick={() => onClickWalletConnectBtn()}>
              <p css={getSpaceGroteskFontStyle(24, 500, "#000000")}>
                CONNECT WALLET
              </p>
            </div>
          </div>
          <div css={getHeightCss(61)} />
        </>
      );
    };
    const renderConnect = () => {
      return (
        <>
          <div css={getHeightCss(48)} />
          <div
            css={[
              directionStyles.row,
              directionStyles.aiCenter,
              css`
                justify-content: flex-end;
              `,
              getHeightCss(50),
            ]}
          >
            <p css={getSpaceGroteskFontStyle(24, 400, "#FEFD4A")}>
              WELCOME! {walletAddrCompression(account as String)}
            </p>
            <div css={getWidthCss(32)} />
            <div
              css={styles.topMyAipButton}
              onClick={() => {
                setTopAipPress(true);
                setTimeout(() => setTopAipPress(false), 3000);
              }}
            >
              <p css={getSpaceGroteskFontStyle(24, 500, "#000000")}>MY AI.P</p>
            </div>
            <div css={getWidthCss(48)} />
          </div>
          <div css={getHeightCss(20)} />
          {topAipPress ? (
            <div
              css={[
                getHeightCss(41),
                directionStyles.row,
                directionStyles.aiCenter,
                css`
                  justify-content: flex-end;
                `,
              ]}
            >
              <p css={getSpaceGroteskFontStyle(32, 500, "#FD26FF")}>
                "YOU DON'T OWN AN AI.P."
              </p>
              <div css={getWidthCss(48)} />
            </div>
          ) : (
            <div css={getHeightCss(41)} />
          )}
        </>
      );
    };
    return !topButtonState ? renderNotConnect() : renderConnect();
  };

  const RenderText = (props: {
    texts: string[];
    fontSize: number;
    fontWeight: number;
    fontColor: string;
    lineHeight?: number;
  }) => {
    const { texts, fontSize, fontWeight, fontColor, lineHeight } = props;

    return (
      <div css={directionStyles.column}>
        {texts.map((text, index) => {
          return (
            <p
              css={[
                css`
                  margin: 0px;
                  padding: 0px;
                  white-space: pre-wrap;
                `,
                getSpaceGroteskFontStyle(
                  fontSize,
                  fontWeight,
                  fontColor,
                  lineHeight
                ),
              ]}
              key={`key-${index}`}
            >
              {text}
            </p>
          );
        })}
      </div>
    );
  };

  const RenderContentItem = (props: {
    heightGap: number;
    topText: string;
    middleText: string[];
    bottomText: string[];
  }) => {
    const { heightGap, topText, middleText, bottomText } = props;
    return (
      <>
        <div css={getHeightCss(heightGap)} />
        <div css={directionStyles.row}>
          <div css={getWidthCss(170 + 333 + 91)} />
          <div css={directionStyles.column}>
            <RenderText
              texts={[topText]}
              fontSize={48}
              fontColor="#FFFFFF"
              fontWeight={500}
              lineHeight={61.25}
            />
            <RenderText
              texts={middleText}
              fontSize={72}
              fontColor="#FFFFFF"
              fontWeight={500}
              lineHeight={91.87}
            />
            <div css={getHeightCss(20)} />
            <RenderText
              texts={bottomText}
              fontSize={20}
              fontColor="#FFFFFF"
              fontWeight={400}
              lineHeight={25.52}
            />
          </div>
          <div css={directionStyles.flex1} />
        </div>
      </>
    );
  };

  const RenderContent1 = useMemo(() => {
    return (
      <>
        <div css={getHeightCss(88)} />
        <div css={directionStyles.row}>
          <div css={getWidthCss(170 + 333 + 91)} />
          {/* <div css={directionStyles.column}>
            <div css={getHeightCss(15)} />
            <div css={styles.boxContainer}>
              <BoxIcon1 />
            </div>
          </div> */}
          <div css={directionStyles.column}>
            <RenderText
              texts={[
                "MEET Ai.P",
                "YOUR AI-POWERED,",
                "EVER-CUSTOMIZABLE",
                " NFT PFP.",
              ]}
              fontSize={72}
              fontColor="#FFFFFF"
              fontWeight={500}
              lineHeight={92}
            />
            <div css={getHeightCss(20)} />
            <div css={directionStyles.row}>
              <div css={directionStyles.column}>
                <div css={directionStyles.flex1} />
                <RenderText
                  texts={["Ai. P - Base Model", "Open Edition, 1 per txn."]}
                  fontSize={24}
                  fontColor="#FFFFFF"
                  fontWeight={400}
                  lineHeight={30.62}
                />
                <div css={getHeightCss(72.86)} />
              </div>
              <div css={getWidthCss(54)} />
              <div css={directionStyles.column}>
                <RenderText
                  texts={[
                    "20,002,405 Ai.Ps in Existence",
                    " ",
                    "Countdown to Launch ",
                  ]}
                  fontSize={24}
                  fontColor="#FFFFFF"
                  fontWeight={400}
                  lineHeight={30.62}
                />
                {!isDisabled ? (
                  <RenderText
                    texts={[leftTime]}
                    fontSize={24}
                    fontColor="#FD26FF"
                    fontWeight={400}
                    lineHeight={30.62}
                  />
                ) : (
                  <div>
                    <div ref={glitch.ref}>
                      <RenderText
                        texts={[leftTime]}
                        fontSize={24}
                        fontColor="#FD26FF"
                        fontWeight={400}
                        lineHeight={30.62}
                      />
                    </div>
                  </div>
                )}
                <div css={getHeightCss(20)} />
                <div
                  css={styles.mintButton}
                  onClick={() => {
                    setDisabled(true);
                    setTimeout(() => setDisabled(false), 2000);
                  }}
                >
                  <p css={getSpaceGroteskFontStyle(24, 500, "#000000")}>
                    MINT Ai.p
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div css={getWidthCss(80)} /> */}
          <div css={directionStyles.flex1} />
        </div>
      </>
    );
  }, [leftTime, isDisabled, topAipPress, glitch.ref]);

  const RenderLine = () => {
    return (
      <>
        <div css={getHeightCss(200)} />
        <div css={styles.line} />
      </>
    );
  };

  const RenderBottom = () => {
    return (
      <>
        <div css={getHeightCss(61)} />
        <div css={directionStyles.row}>
          <div css={getWidthCss(170)} />
          <RenderText
            texts={["AI.P IS BROUGHT TO YOU BY"]}
            fontSize={20}
            fontWeight={400}
            fontColor="#FFFFFF"
          />
          <div css={getWidthCss(716)} />
          <RenderText
            texts={["Join Our Community"]}
            fontSize={20}
            fontWeight={400}
            fontColor="#FFFFFF"
          />
        </div>
        <div css={getHeightCss(46)} />
        <div css={[directionStyles.row, directionStyles.aiCenter]}>
          <div css={getWidthCss(170)} />
          <AeonLogoSvg />
          <div css={getWidthCss(10)} />
          <RenderText
            texts={["AEON STUDIO"]}
            fontSize={27.32}
            fontWeight={700}
            fontColor="#FFFFFF"
            lineHeight={34.85}
          />
          <div css={getWidthCss(74)} />
          <div css={[directionStyles.column, getWidthCss(113)]}>
            <div
              css={[
                css`
                  display: flex;
                  justify-content: center;
                `,
                getWidthCss(113),
              ]}
            >
              <RenderText
                texts={["SOUL"]}
                fontSize={27.32}
                fontWeight={700}
                fontColor="#FFFFFF"
              />
            </div>
            <div css={[directionStyles.row, directionStyles.aiCenter]}>
              <RenderText
                texts={["FICTI"]}
                fontSize={27.32}
                fontWeight={700}
                fontColor="#FFFFFF"
              />
              <SeoulFictionLogoSvg />
              <RenderText
                texts={["N"]}
                fontSize={27.32}
                fontWeight={700}
                fontColor="#FFFFFF"
              />
            </div>
            <div css={getHeightCss(20)} />
          </div>
          <div css={getWidthCss(74)} />
          <div css={[directionStyles.row, directionStyles.aiCenter]}>
            <AiNetworkLogoSvg />
            <div css={getWidthCss(16)} />
            <RenderText
              texts={["AI NETWORK"]}
              fontSize={18}
              fontWeight={700}
              fontColor="#FFFFFF"
            />
          </div>
          <div css={getWidthCss(340)} />
          <DiscordSvg
            onClick={() =>
              window.open(
                "https://discord.com/invite/bnNUjEFuCb",
                "_blank",
                "noreferrer"
              )
            }
          />
          <div css={getWidthCss(70)} />
          <TwitterSvg
            onClick={() =>
              window.open(
                "https://twitter.com/SoulFictionNFT",
                "_blank",
                "noreferrer"
              )
            }
          />
        </div>
        <div css={getHeightCss(73)} />
      </>
    );
  };

  const RenderIcon = useMemo(() => {
    return (
      <div
        css={[
          styles.icon,
          css`
            top: ${iconYPos}px;
          `,
        ]}
      >
        {iconStep === 1 && <LeftIcon1Svg />}
        {iconStep === 2 && <LeftIcon2Svg />}
        {iconStep === 3 && (
          <img
            css={styles.gif}
            src={require("../../images/left-icon-3.gif")}
            alt="frame-icon"
          />
        )}
        {iconStep === 4 && (
          <div>
            <img
              css={styles.gif}
              src={require("../../images/left-icon-3.gif")}
              alt="frame-icon"
            />
            <BadgeSvg css={styles.badge} />
          </div>
        )}
      </div>
    );
  }, [iconYPos, iconStep]);

  const handleOnScroll = (event: any) => {
    let currentIconStep = iconStep;
    if (
      event.currentTarget.scrollTop >= 0 &&
      event.currentTarget.scrollTop < 834 - 333 - 263
    ) {
      currentIconStep = 1;
    } else {
      const checkHeight = (windowSize.height || 0) / 2;
      if (2075 - event.currentTarget.scrollTop < checkHeight) {
        currentIconStep = 4;
      } else if (1545 - event.currentTarget.scrollTop < checkHeight) {
        if (event.currentTarget.scrollTop - 1545 < -170) {
          currentIconStep = 3;
        }
      } else if (989 - event.currentTarget.scrollTop < checkHeight) {
        if (event.currentTarget.scrollTop - 989 < -170) {
          currentIconStep = 2;
        }
      }
    }
    if (currentIconStep === 1) {
      const currentPos = event.currentTarget.scrollTop + 263;
      if (currentPos < 834 - 333) {
        setIconYPos(currentPos);
      }
    } else if (currentIconStep === 2) {
      const currentPos = 1000;
      setIconYPos(currentPos);
    } else if (currentIconStep === 3) {
      const currentPos = 1552;
      setIconYPos(currentPos);
    } else if (currentIconStep === 4) {
      const currentPos = 2088;
      setIconYPos(currentPos);
    }
    setIconStep(currentIconStep);
  };

  return (
    <div css={styles.container}>
      <div css={styles.main} onScroll={handleOnScroll}>
        <RenderTopButton />
        {/* <RenderContent1 /> */}
        {RenderContent1}
        <RenderContentItem
          heightGap={155}
          topText="Q2"
          middleText={["AI Paints;", "Humans Wear It"]}
          bottomText={[
            "Ask the AI to paint over different parts of your PFP.  Customize your Ai.P to",
            "your heart’s content.  For the first time ever, your PFP isn’t valued by how",
            "scarce it is – its value is solely determined by how far you’re willing to go to",
            "customize it.",
          ]}
        />
        <RenderContentItem
          heightGap={187}
          topText="Q3"
          middleText={["Collect Parts;\ncustomize your Ai. P"]}
          bottomText={[
            "Collect parts to add onto your Ai.P as you journey through the Soul Fiction\nuniverse.  Secure limited edition collab parts with some of the most coveted\nartists in crypto art. ",
          ]}
        />
        <RenderContentItem
          heightGap={187}
          topText="Q4"
          middleText={["Community\nEngagement"]}
          bottomText={[
            "Create parts for Ai.P that incentivize a special sort of interaction within your\ncommunity.  With easy step-by-step integration tools, spark fires in your\ncommunity using the Ai.P play culture – from scavenger hunts at birthday\nparties to super serious DAO decisions.",
          ]}
        />
        <RenderLine />
        <RenderBottom />
        {RenderIcon}
      </div>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    justify-content: center;
  `,
  main: css`
    position: relative;
    min-width: 1440px;
    background: #000000;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  `,
  topButton: css`
    display: flex;
    width: 257px;
    height: 50px;
    background: #fefd4a;
    border-radius: 27.8571px;
    justify-content: center;
    align-items: center;
  `,
  topMyAipButton: css`
    display: flex;
    width: 138px;
    height: 50px;
    background: #fefd4a;
    border-radius: 27.8571px;
    justify-content: center;
    align-items: center;
  `,
  mintButton: css`
    display: flex;
    width: 162.71px;
    height: 52.86px;
    background: #ffffff;
    border-radius: 27.8571px;
    justify-content: center;
    align-items: center;
  `,
  boxContainer: css`
    display: flex;
    box-sizing: border-box;
    width: 333px;
    height: 333px;
    background: #000000;
    border: 4px solid #ffffff;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
  `,
  line: css`
    width: 1440px;
    height: 2px;
    background: #555555;
  `,
  icon: css`
    position: absolute;
    left: 170px;
    top: 263px;
  `,
  gif: css`
    width: 333px;
    height: 333px;
  `,
  badge: css`
    position: absolute;
    right: -40px;
    top: -30px;
  `,
};
