/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "../fonts/Font.css";

export const getSpaceGroteskFontStyle = (
  fontSize: number,
  fontWeight: number,
  fontColor: string,
  lineHeight?: number
) => {
  return css`
    font-family: "Space Grotesk";
    font-style: normal;
    font-size: ${fontSize}px;
    font-weight: ${fontWeight};
    color: ${fontColor};
    line-height: ${lineHeight || fontSize}px;
  `;
};
