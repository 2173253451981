/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const getHeightCss = (height: number) => {
  return css`
    height: ${height}px;
  `;
};

export const getWidthCss = (width: number) => {
  return css`
    width: ${width}px;
  `;
};

export const directionStyles = {
  row: css`
    display: flex;
    flex-direction: row;
  `,
  column: css`
    display: flex;
    flex-direction: column;
  `,
  flex1: css`
    flex: 1;
  `,
  aiCenter: css`
    align-items: center;
  `,
  jcCenter: css`
    justify-content: center;
  `,
};
